.chip-container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 5px;
  margin: 0;
  gap: 5px;

  &.MuiPaper-elevation1{
    box-shadow: none;
  }
  &.alerts{
    padding: 10px 0px;
    display: flex;
    align-items: center;
    gap: 50px;
    background: transparent;
  }
  &.location {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    gap: 122px;
    background: transparent;
  }
  &.sensitivity{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    gap: 10px;
    background: transparent;
  }
    &.inspect-content {
      font-size: 14px;
      gap: 10px;
      &.inspect-sensitivity {
        gap: 0px;
        background: transparent;
        &.dataOwner {
          gap: 15px;
        }
        &.dataOwnerTable {
          gap: 6px;
        }
      }
    }
}
.alerts-container {
  display: flex;
  gap: 50px;
  &.inspect-content-header{
    gap:20px;
  }
  &.locationContainer {
    align-items: center;
    gap: 55%;
  }
}
.chip {
  margin: 5px;
}
.additional-content{
  padding: 10px 0px;
}
.addition-child-content {
  display: flex;
  flex-direction: row;
  &.file-count{
    padding-bottom: 15px;
  }
  &.file-date{
    gap: 22px;
  }
  .file-count-align{
    width: 23%;
  }
}
.background {
  position: absolute;
  z-index: 1;
  right: 0;
  svg {
      color: gray;
      circle {
          stroke-dashoffset: 0px !important;
      }
  }
}
.foreground {
  position: relative;
  z-index: 2;
  svg {
      color: blue;
      circle {
      }
  }
}
.box-wrapper{
  position: relative;
}
.value-text {
  position: absolute;
  top: 25%;
  left: 30%;
}

.oneDrive-tooltip-box{
  display: flex;
  flex-direction: column;
  align-items: inherit;

  .tt-header {
    //height: 53px;
    height: auto;
    border-bottom: 1px solid #ccc;
    padding: 8px;

    .heading {
        margin: 0px 0 0;
        color: #292929;
        font-family: monospace;
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 0;
        line-height: 22px;
        max-width: 160px;
    
        &.apmData {
          max-width: 250px;
        }
      }


    span.alert-type {
      height: 20px;
      width: 46.67px;
      color: #FF2251;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 10px;
      text-align: center;
      background: #ffbcca;
      padding: 2px 5px;
    }

    .alert-badge{
      margin-left: 45px;
      cursor: pointer;
      &.high .MuiBadge-badge{
        color: #ff2251;
        background-color: rgb(255, 197, 209);
      }
      &.medium .MuiBadge-badge{
        color: #ff8421;
        background-color: rgb(255, 215, 182);
      }
      &.low .MuiBadge-badge{
        color: #081981;
        background-color: rgb(190, 195, 221);
      }
    }

    span.alert-no {
      margin-left: 5px;
      background: #ff2251;
      color: #fff;
      padding: 2px 8px;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 10px;
      text-align: center;
    }
  }

  .tt-body {
    padding: 0px 10px;
    p {
      margin: 5px 0;
      font-family: inherit;
      color: #292929;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }

    span.tables-list {
      border-radius: 4px;
      background-color: #DFE1E9;
      display: inline-block;
      padding: 8px;
    }
  }

  .border-top {
    border-top: 1px solid #ccc;
  }

  .tt-footter {
    padding: 8px;
    border-top: 1px solid #ccc;

    p {
      margin: 0;
      color: #081981;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }

    .arrow {
      display: inline-block;
      float: right;
    }
  }
}
.squareBadge {
  height: 24px;
  min-width: max-content;
  border-radius: 2px;
  display: flex;
  align-items: center;
  &.high{
    background-color: #FF2251;
  }
  &.medium{
    background-color: #FF8429;
  }
  &.low{
    background-color: #081981;
  }
}
.squareBadge-text{
  color: white;
  padding: 6px;
}

.inspectHeader{
  display: flex;
  align-items: center;
  width: 211px;
  padding: 5px;
  &.oneDrive-header{
    gap: 10px;
  }
}
.oneDriveColorIcon{
  width: 35%;
}
.GoogleDriveIcon {
  width: 72%;
  padding-top: 7px;
  margin-left: 10px;
}
.EntraIcon{
  width: 70%;
  margin-left: 5px;
  margin-top: 5px;
}
.DropboxIcon {
  width: 20%;
}
.inspectFotter{
  display: flex;
  align-items: center;
  gap: 20px;
}
.inspectIcon{
  width: 13%;
}
.inspect-header-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inspect-tooltip-close{
  cursor: pointer;
}
.locationBadges {
  display: flex;
  align-items: center;
  gap: 5px;
}