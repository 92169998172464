.RRContainer {
  .riskPilotTable {
    display: flex;
    /* align-items: end; */
    justify-content: end;
    margin-bottom: 15px;
  }

  .MuiButtonBase-root {
    color: #dddddd;
    background: #2999ca;
    width: 100px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;

    &:hover {
      background-color: #23bcff;
    }
  }

  .MuiButton-root.Mui-disabled {
    color: #FAFAFA;
    background: #AAAAAA;
  }

  &.riskPilotTable {
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
  }
}

.noDataFlexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createRRText {
  font-size: 18px;
}

.createRRHeader {
  height: 105px;
  font-family: Nunito-Regular;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
  padding-left: 210px;
  background: linear-gradient(rgba(241, 242, 246, 0), #dfe1e9);

  &.RiskGovernance {
    height: 95px;
  }
  &.sysGuard{
    height: 95px;
    margin: 0px;
  }
}

.riskRegisterContainer {
  margin-top: 2%;
  height: 100%;
  width: 82%;
}

.riskRegisterHeading {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.threat {
    margin-bottom: 0px;
  }
  &.riskId {
    margin-bottom: 18px
  }
}

.riskRegisterFormInput {
  box-sizing: border-box;
  height: 30px;
  width: 278px;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;

  .inputContainer {
    padding-left: 10px;
  }

  &.vulnerability {
    height: 80px;
  }

  .MuiInputBase-input {
    height: 0.95rem;
  }

  &.riskEditScreen { 
    width: 100px;
  }
}

.riskRegisterTextArea {
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;

  &:hover {
    background-color: #ffffff !important;
  }
}

.riskRegisterTextAreaInput {
  width: 100% !important;
  border: none;
  font-size: 15px;
  font-family: 'Nunito-Regular';
  border-radius: 5px;
}

.riskRegisterTextAreaInput:hover {
  background-color: white !important;
  /* Maintain the white background on hover */
  border-color: #aaa;
  /* Optional: Change the border color on hover for better UX */
}

.riskRegisterTextAreaInput:focus-visible {
  outline: none;
}

.mitigationPriorityContainer {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-top: 2%;
}

.riskRegisterSideBarContainer {
  width: 170%;
}

.riskRegisterNumberInput {
  width: 100% !important;
}

.header_ContainerRR {
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}

.mitigationParentContainer {
  display: flex;
  align-items: center;
}

.mitigationSideBarContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.riskMultiTitleContainer {
  display: flex;
  align-items: center;
}
.secondTitle {
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  color: dimgray;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}
.riskContainer {
  .MuiOutlinedInput-input {
    padding: 5px 0px 5px 14px;
  }

  &.threats {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 24px;
    margin-top: 3%;
  }
}

.riskHeaderDetails {
  font-size: 16px;
  font-weight: 600;
}

.riskThreatContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0px 16px 16px;
  .MuiButtonBase-root {
    width: 0px;
  }
}

.riskAccordionContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 10px;
  overflow-y: auto;
  max-height: 500px;
}

.riskRegisterAccordion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 15px;
  max-height: 520px;
  overflow-y: auto;
  overflow-x: hidden;

  .MuiButtonBase-root {
    color: #dddddd;
    text-transform: capitalize;
    background: none;
    width: auto;
    font-size: 15px;
    font-weight: 600;

    &:hover {
      background-color: white;
    }
  }

  .MuiAccordion-root {
    width: 100%;
  }
}

.riskMultiContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.riskSelect {
  width: 320px;

  .impact {
    .MuiInputBase-input {
      padding: 10px 12px 9px 7px;
    }
  }
}

.riskTypeEdit {
  max-width: 320px;
  min-width: 145px;
}

.riskAndThreatContainer {
  display: flex;
  align-items: center;
  gap: 35px;
  padding: 8px 16px 0px;
  &.riskHeader {
    padding: 8px 35px 0px;
  }
  padding: 0px 16px 0px;
}

.createRRButton {
  margin-top: 25px;
  margin-left: 40px;
}

.createAndBackContainer {
  display: flex;
  align-items: center;
}

.createAndBackImage {
  width: 20px;
  height: 25px;
}

.riskRegisterActionContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.riskRegisterTableContainer {
  width: 100%;
}

.riskRegisterTableSearchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.riskRegisterCancelIcon {
  width: 12px;
  padding: 0 15px;
}

.riskRegisterTotalContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.riskRegisterTitle {
  font-size: 17px !important;
  font-weight: 600 !important;
  white-space: nowrap;
}

.riskRegisterMiniContainer {
  display: flex;
  align-items: center;
}

.riskRegisterFilterContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 56%;
}

.threatContainer {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  border: 1px solid #CCCCCC;
  border-radius: 5px;

  p {
    color: black;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionDetails-root {
    background: #edf5f9;
    padding: 0px;

    &:hover {
      background-color: #edf5f9;
    }
  }
}

.riskGovernanceHeaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.riskHeaderTitle {
  margin: 0px;
  font-size: 32px;
}

.customRiskButtonContainer {
  .MuiButton-root {
    background-color: #2999ca;
    color: white;
    text-transform: none;
    margin-right: 25px;
    width: 125px;
    height: 32px;

    &:hover {
      background-color: #23bcff;
    }
  }
}


.region {
  display: flex;
  align-items: baseline;
}

.vulnerabilityContainer {
  background: #d5eaf3;
  display: flex;
  align-items: baseline;
  gap: 20px;
  border-bottom: 1px solid #C5C4C4;

  .MuiButtonBase-root {
    background: #d5eaf3;

    &:hover {
      background: #d5eaf3;
    }
  }

  .MuiAccordionDetails-root {
    background: #d5eaf3;
  }

  .MuiAccordion-root :hover {
    background: #d5eaf3;
  }
}

.vulnerabilityChildContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 7px;
  gap: 10px;
  background: #d5eaf3;
}

.dropdown-btnRR {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: 200px;
  height: 0px;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;

  &.button-open {
    border: rgb(147, 197, 253) 2px solid;
  }
}

.toggle-iconRR {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.dropdown-contentRR {
  position: absolute;
  min-width: -moz-fit-content;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-height: 40vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 2;

  &.riskIndividualFilters {
    margin-left: 235px;
    width: 280px;
  }
}

.dropdown-contentRR::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.dropdownContainerRR {
  position: relative;
}

.dropdownOptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputTextColors {
  width: max-content;
  padding: 6px;
  line-height: 0.8rem;
  border-radius: 2px;
  cursor: pointer;

  &.HIGH {
    color: #ff2251;
    background: #febbcb;
  }

  &.MEDIUM {
    color: #ff8421;
    background: #fde5d3;
  }

  &.LOW {
    color: #081981;
    background: #A8D5FF;
  }
}

.showFiltersContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.showFilterHeader {
  color: #808087;
  font-weight: 600;
}

.showFilterChild {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #CEABE1;
  border-radius: 34px;
  height: 25px;
  width: max-content;
}

.showFilterChildText {
  font-size: small;
  padding-left: 5px;
  font-weight: 600;

  &.HIGH {
    color: #ff2251;
  }

  &.MEDIUM {
    color: #ff8421;
  }

  &.LOW {
    color: #081981;
  }
}

.showFilterChildImage {
  padding: 5px;
  cursor: pointer;
  width: 20px;
}

.showFilterChildContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.allFiltersContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
}

.riskPopUpDialogContainer {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;

  .MuiDialog-paper {
    color: #000;
    width: 440px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid #2999ca;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    border-radius: 4px;
  }
}

.riskPopUpText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}

.riskPopUpMessage {
  color: gray;
}

.riskPopUpTextContainer {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 10px;
}

.riskPopUpButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.riskContinueButton {
  .MuiButton-root {
    background-color: #2999ca;
    color: white;
    text-transform: none;
    width: 124px;
    height: 32px;

    &:hover {
      background-color: #23bcff;
    }
  }
}

.riskCancelButton {
  .MuiButton-root {
    width: 124px;
    height: 32px;
    box-shadow: rgba(1, 18, 121, 0.5) 0px 5px 8px -3px;
    color: #081981;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    background-color: white;
  }
}

.riskPopTextContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: baseline;
}

.riskThreatHeading {
  display: flex;
  gap: 5px;
  align-items: center;
}

.riskThreatHeaderTypography {
  width: 100%;
}

.riskThreatHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.threatDeleteButton {
  margin-top: 8px;
}

.riskSeverityContainer {
  border-radius: 20px;
  width: max-content;
  margin-top: 30px;

  &.CRITICAL {
    border: 1px solid #FF2251;
    background: #febbcb;
  }

  &.HIGH {
    border: 1px solid #FF2251;
    background: #febbcb;
  }

  &.MEDIUM {
    background: #FBE1CC;
    border: 1px solid #FF8421;
  }

  &.LOW {
    background: #A8D5FF;
    border: 1px solid #081981;
  }
  &.mitigation {
    margin-top: 0px;
  }
}

.riskSeverityValue {
  line-height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;

  &.CRITICAL {
    color: #FF2251;
  }

  &.HIGH {
    color: #FF2251;
  }

  &.MEDIUM {
    color: #FF8421;
  }

  &.LOW {
    color: #081981;
  }
}

.mitigationContainer {
  background: #bbdeed;
  display: flex;
  align-items: baseline;
  gap: 20px;
  border-bottom: 1px solid #C5C4C4;

  .MuiButtonBase-root {
    background: #bbdeed;

    &:hover {
      background: #bbdeed;
    }
  }

  .MuiAccordionDetails-root {
    background: #bbdeed;
  }

  .MuiAccordion-root :hover {
    background: #bbdeed;
  }

  .MuiAccordion-root {
    width: 100%;
  }
}

.mitigationChildContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 7px;
  gap: 10px;
  background: #bbdeed;

}

.mitigationStatusParentContainer {
  .MuiInputBase-input :hover {
    border: none;
    background: white;
  }

  .MuiSelect-root :hover {
    background: white;
  }

  .MuiSelect-outlined:hover {
    background: white;
  }

  .MuiAccordion-root :hover {
    background: white;
  }

  .MuiInputBase-root :hover {
    border: none;
  }

  .Mui-focused {
    border: none;
  }
}

.mitigationStatusContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.mitigationCountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mitigationCountChildContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  &.riskCategories {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.mitigationStatusCount {
  border-radius: 10px;
  color: white;
  white-space: nowrap;

  &.TODO {
    background: #E94E6F;
  }

  &.OPEN {
    background: #ED9E00;
  }

  &.IN_PROGRESS {
    background: #EF5B02;
  }

  &.CLOSED {
    background: #05b778;
  }
  &.filters {
    cursor: pointer;
  }
}

.mitigationTotal {
  margin-top: 5px;
  font-weight: 700;
}

.statusCount {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  &.riskSummary {
    font-size: 14px;
  }
}

.threatLevelChartLine {
  border: 1px solid #D9D9D9;

  &.mPriority {
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }
}

.threatPieChart {
  width: 90%;
  margin-left: 15px;
  box-shadow: 0px 4px 4px 0px #0000001A;
  white-space: nowrap;

  &.totalRisk {
    width: 96%;
  }
  &.riskCategory {
    width: 99%;
    margin-left: 0px;
  }
  &.threatByRiskLevel {
    width: 94%;
    margin-left: 0px;
  }
  .MuiCardContent-root {
    padding: 0px;
  }

  .MuiTypography-h5 {
    font-size: 22px !important;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }

  .main-dashboard-container .medium-row .MuiCard-root {
    height: 400px;
    width: 515px;
    margin-left: 15px;
    overflow-y: auto;
  }
}


.barChartParentRiskContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  &.riskCategories{
    width: 100%;
    overflow-x: auto;
  }
}

.barChartRiskContainer {
  display: flex;
  align-items: center;
  margin-left: 5%;
  height: 62px;
}

.barChartRisk {
  display: flex;
  align-items: center;
}

.riskTable {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
}

.riskTable th,
.riskTable td {
  padding: 4px;
  text-align: left;
}

.riskTable th {
  background-color: white;
}

.riskTable tr:nth-child(even) {
  background-color: #e0ebef;
}

.riskTable tr:nth-child(odd) {
  background-color: #c5e2ee;
}

.level.critical {
  color: #CC1C3E
}

.level.high {
  color: #FF2251;
}

.level.medium {
  color: #F68D2B;
}

.level.low {
  color: #344BFD;
}

.status {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 5px;
}

.status.red {
  background-color: #ff0000;
  color: white;
}

.status.orange {
  background-color: #ffa500;
  color: white;
}

.status.yellow {
  background-color: #ffff00;
  color: black;
}

.status.green {
  background-color: #008000;
  color: white;
}

.mitigationOwnerWidget h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.mitigationOwnerWidget .chartContainer {
  position: relative;
  width: 100%;
  height: 400px;
}

.mitigationParentLegendContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15%;

  &.mPriority {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 10%;
  }
}

.MitigationLegendChild {
  display: flex;
  align-items: center;
  margin-left: 40%;
  gap: 10px;
  margin-top: 10%;
  color: #767676;
  font-weight: 600;

  &.mPriority {
    display: flex;
    align-items: center;
    margin-top: 5%;
    margin-left: 10%;
  }
}

.textContainer {
  width: 260px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.barChartRiskContainer h1 {
  font-size: 24px;
  font-weight: bold;
}

.chartContent {
  display: flex;
  align-items: baseline;
  margin-top: 3%;
  flex-direction: column;
}

.chartWrapper {
  position: relative;
  max-width: 270px;
  height: 170px;
}

.centerLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

.centerLabel.priority {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  font-size: 16px;
}

.centerLabel.MEDIUM {
  top: 65%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #ECEAF8;
}

.centerLabel.LOW {
  top: 10%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #ECEAF8;
}

.centerLabel.HIGH {
  top: 25%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #ECEAF8;
}

.legendImage {
  margin-right: 8px;
}

.radialChartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mPriorityContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 3%;
  max-width: 600px;
  height: 286px;
}

.riskPilotHeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
}

.gaugeChartContainer {
  border-radius: 10px;
  width: 30%;
  height: 5%;
  margin-bottom: 20px;
  background: #29023D;
}

.gaugeParentChartContainer {
  position: relative;
  height: 180px;
  width: 300px;
  margin-left: 6%;
  padding: 10px;
  display: flex;
  align-items: end;
}

.riskPilotChildContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.riskPilotCardParentContainer {
  display: flex;
  flex-direction: column;
  width: 186px;
  height: 216px;
  margin-bottom: 20px;
  background: #FFE2E5;
  border-radius: 10px;
}

.statusCardContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  gap: 30px;
}

.flightParentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.totalRiskHeading {
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-weight: 700;

  h3 {
    font-size: 22px;
    font-weight: 700;
  }

}

.riskPilotCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55%;
}

/* Media query for different zoom levels */
@media (max-width: 1919px) {
  .threatPieChart .MuiTypography-h5 {
    font-size: 19px !important;
  }
  .flightParentContainer{
    width: 100%
  }
  .totalRiskHeading {
    h3 {
      font-size: 19px;
      font-weight: 700;
    }
  }
  .riskPilotCardContainer {
    height: 58%;
  }
}

@media (max-width: 1550px) {
  .riskPilotCardContainer {
    height: 66%;
  }
}

.riskTypeIconsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;

  &.iconSelector {
    gap: 0%;
  }
  &.dashboardIcons {
    justify-content: unset;
  }
  &.disabledPointer {
    cursor: not-allowed;
  }
}

.riskTypeImageContainer {
  display: flex;
  align-items: center;
  img{
    width: 64%;
  }
}

.riskSnackBarContainer {
  .MuiSnackbar-anchorOriginTopRight {
    top: 100px
  }
}

.riskThreatDataContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.riskPilotID {
  font-size: 14px;
  font-weight: 700;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.riskTypeParentContainer {
  display: flex;
  align-items: center;
  gap: 3%;
  font-size: 16px;
  font-weight: 600;
}
.riskTypeIconContainer{
  font-size: 14px;
  white-space: nowrap;
}

.riskSummaryContainer {
  display: flex;
  align-items: center;
  gap: 50px;
}

.addMitigationIcon {
  width: 20%;
}

.riskSummaryThreatContainer{
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 24px;
}
.riskSummaryImageContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.riskSummaryImageSize {
  width: 25%;
}
.riskSummaryMitigationImageSize {
  width: 20%;
}
.riskSummaryTextInfo {
  font-size: 16px;
  font-weight: 700;
}
.riskSummaryInfo {
  font-weight: 700;
  &.threat {
    margin-left: 2px;
  }
  &.vulnerability {
    margin-left: 5px;
  }
  &.totalMitigationCount {
    font-size: 14px;
    white-space: nowrap;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.riskInfoSummaryDataContainer {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  &.vulnerability {
    max-width: 70px;
  }
}

.summaryMitigationCountContainer {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-left: 5px;
}
.riskAddThreatContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.multiThreatIconCustomSizes {
  margin-right: 5px;
  margin-left: 5px;
  &.threatIconCustomSize {
    width: 20px;
  }
  &.threatVulnIcon {
    width: 20px;
  }
  &.threatMitigationIcon {
    width: 20px;
  }
  &.mitigationIcon {
    width: 15px;
  }
}
.riskGeneralStyle {
  display: flex;
  gap: 10px;
  align-items: center;
}
.mitigationCounts {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.riskLoader {
  position: absolute;
  margin-left: 50%;
  top: 45%;
  z-index: 10;
}
.star-bg{
  background-image:
radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
}

.mitigationTableHeader {
  height: 1000;
  width: 100%;
  &.noData {
    height: 160px;
  }
}
.mitigationFilterPanel {
  padding: 10px;
}
.filterRow {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-bottom: 10px;
}
.filterButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .applyFilterButton {
    padding-top: 10px;
  }
}
.filterButtons {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  gap: 10px;
}
.filterField {
  width: 155px;
}
.operatorField {
  width: 120px;
}
.titleFilter {
  display: flex;
  align-items: center;
  gap: 5px;
}
.notAllowed{
  cursor: not-allowed;
}
.riskTableAction {
  cursor: pointer;
}